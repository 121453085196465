import React, { useContext } from 'react'
import { AppContext } from "./AppContext";

const MessageBar = () => {
  const [appState, setAppState] = useContext(AppContext);

  const message = appState.winner ? 
    <span>Player {appState.winner} won!</span> :
    <span className={`message-bar-color-${appState.currentPlayer}`}>Player {appState.currentPlayer}'s turn</span>

  return (
    <div className="message-bar">
      {message}
    </div>
  )
}

export default MessageBar
