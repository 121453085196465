import React, { useContext, useState } from 'react'
import { AppContext } from "./AppContext";

const checkIfColumnIsFull = columnIndex => {
  clickedSpace.p !== 0
}

const getNextAvailableSpaceInColumn = (column) => {
  console.log('column', column)
  //column.find(space => space.p === 0)
}

const checkIfSpaceBelowIsFilled = (spaces, clickedSpace) => {
  if (clickedSpace.y === 0) return true

  const spaceBelow = spaces.find((space) => {
    return space.x === clickedSpace.x && space.y === clickedSpace.y - 1
  })

  if (spaceBelow.p === 0) {
    return false
  } else {
    return true
  }
}

const checkIfPlayerWon = (spaces, clickedSpace) => {
  const winReducer = (accumulator, val, i, array) => {
    // "break out" of reduce
    if (accumulator === true) return true
    
    // increase or reset accumulator count
    if (val.p === player) {
      accumulator ++
    } else {
      accumulator = 0
    }

    // if connected 4
    // else if reached end of row and did not connect 4 
    if (accumulator === 4) {
      return true
    } else if (i + 1 === array.length) {
      return false
    } else {
      return accumulator
    }
  }

  const x = clickedSpace.x
  const y = clickedSpace.y
  const player = clickedSpace.p

  const verticalWin = spaces
    .filter(space => space.x === x)
    .reduce(winReducer, 0)

  const horizontalWin = spaces
    .filter(space => space.y === y)
    .reduce(winReducer, 0)

  const forwardSlashWin = spaces
    .filter(space => space.x - space.y === clickedSpace.x - clickedSpace.y)
    .reduce(winReducer, 0)

  const backslashWin = spaces
    .filter(space => space.x + space.y === clickedSpace.x + clickedSpace.y)
    .reduce(winReducer, 0)

  return [verticalWin, horizontalWin, forwardSlashWin, backslashWin].includes(true)
}

const Grid = () => {
  const [appState, setAppState] = useContext(AppContext);

  const handleSpaceClick = (clickedSpace) => {

    const nextAvailableSpaceInColumn = appState.spaces
      .filter(space => space.x === clickedSpace.x)
      .reverse()
      .find(space => space.p === 0)

    if (nextAvailableSpaceInColumn === undefined) return

    // ensure space is not already filled and space below is
    //if (isSpaceFilled || !isSpaceBelowFilled) return
    // TODO: change to isValidMove function?

    // update clicked space with player color
    setAppState({
      ...appState,
      spaces: appState.spaces.map(space => {
        if (
          space.x === nextAvailableSpaceInColumn.x && 
          space.y === nextAvailableSpaceInColumn.y
        ) {
          space.p = appState.currentPlayer
        }
        return space
      })
    });

    // check win condition
    const win = checkIfPlayerWon(appState.spaces, nextAvailableSpaceInColumn)

    // switch players or show winner
    if (win) {
      setAppState({
        ...appState,
        winner: appState.currentPlayer,
        isModalOpen: true,
        modalView: 'win'
      });
    } else {
      setAppState({
        ...appState,
        currentPlayer: appState.currentPlayer === 1 ? 2 : 1
      });
    }
  }

  return (
    <div className="grid">
      {appState.spaces.map((space) => (
        <div 
          key={`${space.x}-${space.y}`}
          className={`space space-x-${space.x}-y-${space.y} space-fill-${space.p}`}
          onClick={() => handleSpaceClick(space)}
        >
        </div>
      ))}
    </div>
  )
}

export default Grid
