import initializeSpaces from './initializeSpaces.js'

const constants = {
  version: '0.0.8',
  initialAppState: {
    currentPlayer: 1,
    spaces: initializeSpaces(),
    winner: 0,
    isModalOpen: true,
    modalView: 'intro'
  }
}

export default constants