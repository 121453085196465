import React, { useContext } from 'react'
import { AppContext } from './AppContext'
import constants from '../utils/constants.js'
import initializeSpaces from '../utils/initializeSpaces.js'

const Modal = () => {
  const [appState, setAppState] = useContext(AppContext);

  const closeModal = () => {
    setAppState({ ...appState, isModalOpen: false})
  }

  const resetBoard = () => {
    console.log('yo', constants.initialAppState)
    setAppState({
      ...appState,
      currentPlayer: 1,
      spaces: initializeSpaces(),
      winner: 0,
      isModalOpen: false
    })
  }

  let modalContent = ''

  switch (appState.modalView) {
    case 'intro': modalContent = (
      <div className="modal-content">
        <div className="modal-title">Time to play C4!</div>
        <button className="modal-button" onClick={closeModal}>Start</button>
      </div>
    )
    break
    case 'win': modalContent = (
      <div className="modal-content modal-content--winner">
        <div className="modal-title">Player {appState.winner} Wins 🎉</div>
        <button className="modal-button" onClick={resetBoard}>Play Again</button>
      </div>
    ); break    
  }

  return appState.isModalOpen ? (
    <div className="modal">
      {modalContent}
    </div>
  ) : null
}

export default Modal