const initializeSpaces = () => {
  let xCounter = 0
  let yCounter = 5

  const initialSpaces = Array(42).fill({}).map(space => {
    space = {
      x: xCounter,
      y: yCounter,
      p: 0
    }
  
    if (xCounter === 6) {
      xCounter = 0
      yCounter = yCounter - 1
    } else {
      xCounter ++
    }
  
    return space
  })

  return initialSpaces
}

export default initializeSpaces